import styled from "styled-components";
import { Constants } from "config/constants";
import { CustomLink, StyledSubmitButton } from "components/utils/buttons";

const colors = Constants.theme.colors;

export const HeaderContainer = styled.div`
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: ${colors.lightGrey};
  box-shadow: ${(props) =>
    props.transparent ? "none" : "0px 0px 3px 0px rgba(0,0,0,0.15)"};
`;

export const HeaderRow = styled.div`
  display: flex;
  background-color: ${colors.lightGrey};
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: auto;
  padding: 10px;
  min-height: 120px;
`;

export const SignupButton = styled(StyledSubmitButton)`
  margin: 0 10px;
  width: fit-content;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 15px 25px;
`;

export const LoginButton = styled(StyledSubmitButton)`
  margin: 0 10px;
  width: fit-content;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 15px 25px;
  background-color: transparent;
  color: ${colors.charcoalGrey};
  transition: all 0.3s ease-out;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.secondary};
  }

  &:active {
    background-color: ${colors.primary};
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  height: auto;

  .menu-button {
    display: none;
    text-align: center;
    transition: all 0.8s ease-out;

    svg {
      color: ${colors.charcoalGrey};
    }

    @media (max-width: 900px) {
      display: block;
    }
  }

  .auth-button {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const NavBar = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  height: auto;

  @media (max-width: 900px) {
    display: none;
  }

  .active {
    transition: 0.1s ease-out;
    font-weight: 600;
    color: ${colors.primary};

    &:hover {
      color: ${colors.primary};
    }

    &:active {
      font-weight: 700;
    }
  }
`;

export const NavItem = styled(CustomLink)`
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  min-width: 90px;
  text-align: center;
  margin: 0 5px;
  cursor: pointer;
  color: ${colors.charcoalGrey};

  &:hover {
    font-weight: 600;
  }

  &:active {
    font-weight: 700;
  }

  .active {
    color: ${colors.primary};
  }
`;
