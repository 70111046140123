import styled from "styled-components";
import { StyledHeader, StyledParagraph } from "./text";
import { Constants } from "config/constants";

const colors = Constants.theme.colors;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.align ? props.align : "left")};

  .header-title {
    width: fit-content;
    padding: 5px 10px;
    border-radius: 1000px;
    color: ${colors.secondary};
    font-size: 0.9rem;
    border: 2px solid ${colors.secondary};
  }

  .main-header {
    color: ${(props) => (props.light ? colors.lightGrey : colors.charcoalGrey)};
    font-size: 3rem;
    margin: ${(props) => (props.sub ? "0" : "10px")} 0;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .sub-header {
    margin: 10px 0;
    font-size: 1.2rem;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  .header-content {
    font-size: 1.2rem;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;

export const HeadingBlock = (props) => {
  const { category, header, sub, text, align, light } = props;
  return (
    <HeaderWrapper
      align={align}
      sub={sub}
      light={light}
      className='header-block'
    >
      {category && (
        <StyledParagraph className='header-title'>{category}</StyledParagraph>
      )}
      <StyledHeader className='main-header'>{header}</StyledHeader>
      {sub && <StyledParagraph className='sub-header'>{sub}</StyledParagraph>}
      {text && (
        <StyledParagraph className='header-content'>{text}</StyledParagraph>
      )}
    </HeaderWrapper>
  );
};
