import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import * as Scroll from "react-scroll";

const Context = React.createContext();

export const ContextWrapper = ({ children }) => {
  const [menu, setMenu] = useState(false);
  const [current, setCurrent] = useState(window.location.pathname);
  const [loading, setLoading] = useState(true);
  const [dark, setDark] = useState(true);
  const [scrolled, setScroll] = useState(false);
  const scroll = Scroll.animateScroll;

  const toggleMenu = () => {
    setMenu(!menu);
  };

  const nav = useNavigate();

  const navigate = (path) => {
    if (menu) toggleMenu();
    setLoading(true);
    setCurrent(path);
    handleScrollUp();
    nav(path);
  };

  const checkScrollPosition = () => {
    if (window.scrollY > 1) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }

    window.addEventListener("scroll", checkScrollPosition);

    return () => {
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, [loading]);

  const redirect = (path) => {
    window.location.replace(path);
  };

  const handleScrollUp = () => {
    checkScrollPosition();
    scroll.scrollToTop({
      duration: 1000,
      delay: 100,
      smooth: true,
    });
  };

  const handleStartScroll = () => {
    scroll.scrollTo(window.innerHeight - 100, {
      duration: 1000,
      delay: 100,
      smooth: true,
    });
  };

  const toggleTheme = () => {
    setDark(!dark);
  };

  const contextData = {
    loading,
    setLoading,
    redirect,
    scrolled,
    handleScrollUp,
    handleStartScroll,
    dark,
    toggleTheme,
    menu,
    toggleMenu,
    navigate,
    current,
    setCurrent,
  };

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};

export const usePlatformContext = () => {
  return useContext(Context);
};
