import styled from "styled-components";
import { Constants } from "../../config/constants";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { Link as Scroller } from "react-scroll";
import { usePlatformContext } from "config/context";

const colors = Constants.theme.colors;

const DefaultButton = (props) => {
  const context = usePlatformContext();
  return (
    <button
      {...props}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        } else {
          context.navigate("/redirect?url=" + process.env.REACT_APP_AUTH);
        }
      }}
    />
  );
};

export const StyledIconButton = styled(IconButton)`
  margin-left: auto;
  margin-right: 0;
  padding: 0;

  &:hover {
    background: transparent;
    border-radius: 1000px;
    transition: 0.2s ease-out;
  }

  svg {
    font-size: ${(props) => (props.size ? props.size : "1.7rem")};
    color: ${(props) => props.color || colors.white};
  }
`;

export const StyledSubmitButton = styled(DefaultButton)`
  border: none;
  padding: 8px 12px;
  font-size: ${(props) => props.fontSize || "1rem"};
  font-weight: ${(props) => props.fontWeight || "600"};
  background: ${(props) => props.background || colors.primary};
  border-radius: 1000px;
  color: ${colors.white};
  margin-bottom: 10px;
  margin: ${(props) => props.margin};
  cursor: pointer;
  transition: 0.2s ease-out;

  &:hover {
    background-color: ${colors.secondary};
    opacity: 0.9;
    transition: 0.2s ease-out;
  }

  &:active {
    background-color: ${colors.primary};
    opacity: 0.8;
    transition: 0.2s ease-out;
  }

  &:disabled {
    background: ${colors.mediumGrey};
    color: ${colors.darkGrey};
    cursor: not-allowed;
  }
`;

export const ScrollLink = (props) => {
  const context = usePlatformContext();
  return (
    <Scroller
      {...props}
      activeClass='active'
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      onClick={() => {
        if (context.menu) context.toggleMenu();
      }}
    />
  );
};

export const CustomLink = (props) => {
  const context = usePlatformContext();
  return (
    <Link
      {...props}
      onClick={() => {
        context.setLoading(true);
        context.handleScrollUp();
        if (context.menu) context.toggleMenu();
        context.setCurrent(props.to);
        props.onClick && props.onClick();
      }}
    />
  );
};
