import styled from "styled-components";
import { Constants } from "config/constants";
import {
  StyledHeader,
  StyledLink,
  StyledParagraph,
  StyledScroller,
} from "components/utils/text";

const colors = Constants.theme.colors;

export const CopyRight = styled(StyledParagraph)`
  font-size: 1rem;
  font-weight: 500;
`;

export const FooterColumn = styled.div`
  max-width: 15rem;
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => (props.expand ? "0" : "1rem")};
  flex: 1;
  justify-content: ${(props) =>
    props.expand ? "space-between" : "flex-start"};

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: ${colors.lightGrey};
  align-items: center;
  border-top: 1px solid ${colors.mediumGrey};
`;

export const FooterRow = styled.div`
  width: 100%;
  max-width: 1200px;
  background-color: ${colors.lightGrey};
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 2rem 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FooterLinksRow = styled.div`
  justify-content: end;
  display: flex;
  flex-direction: row;
  flex: 2;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    margin-top: 2rem;
    flex-direction: column;
  }
`;

export const FooterHeader = styled(StyledHeader)`
  color: ${colors.charcoalGrey};
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

export const FooterLink = styled(StyledLink)`
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 550;
  margin-bottom: 0.8rem;
  color: ${colors.charcoalGrey};
`;

export const FooterScroller = styled(StyledScroller)`
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 550;
  color: ${colors.charcoalGrey};
  margin-bottom: 0.8rem;
`;
