import { usePlatformContext } from "config/context";
import {
  ButtonRow,
  HeaderContainer,
  HeaderRow,
  LoginButton,
  NavBar,
  NavItem,
  SignupButton,
} from "./header.styles";
import { AiOutlineMenu } from "react-icons/ai";
import { Logo } from "components/utils/logo";
import { StyledIconButton } from "components/utils/buttons";

export const Header = (props) => {
  const context = usePlatformContext();
  const pages = ["/", "/features", "/pricing", "/why-us"];
  const names = ["Home", "Features", "Pricing", "Why Us"];
  return (
    <HeaderContainer transparent={!context.scrolled}>
      <HeaderRow>
        <Logo />
        <NavBar>
          {pages.map((page, index) => {
            return (
              <NavItem
                key={index}
                to={page}
                className={context.current === page && "active"}
              >
                {names[index]}
              </NavItem>
            );
          })}
        </NavBar>
        <ButtonRow>
          <LoginButton className='auth-button'>Login</LoginButton>
          <SignupButton className='auth-button'>Get Started</SignupButton>
          <StyledIconButton
            className='menu-button'
            onClick={context.toggleMenu}
          >
            {context.menu ? null : <AiOutlineMenu />}
          </StyledIconButton>
        </ButtonRow>
      </HeaderRow>
    </HeaderContainer>
  );
};
