import styled from "styled-components";

const Section = styled.section`
  width: 100%;
  margin-bottom: ${(props) => props.margin || "30px"};
  padding: ${(props) => props.padding || "0 15px"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: ${(props) => (props.hero ? "calc(100vh - 120px)" : 0)};
`;

export const SectionWrapper = ({ children, ...props }) => {
  return <Section {...props}>{children}</Section>;
};
