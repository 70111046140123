import { StyledSubmitButton } from "components/utils/buttons";
import { SectionWrapper } from "components/utils/section-wrapper";
import { StyledHeader, StyledParagraph } from "components/utils/text";
import { Constants } from "config/constants";
import styled from "styled-components";

const colors = Constants.theme.colors;

const HeroBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: transparent;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: auto;
  max-width: 1200px;
`;

const HeroBackGroundImage = styled.div`
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  right: ${(props) => props.right};
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => props.src});
  position: absolute;
  width: 300px;
  height: 300px;
  background-size: 900px;
  opacity: 0.6;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
`;

const HeroGradient = styled.div`
  width: inherit;
  height: inherit;
  position: absolute;
  background: radial-gradient(
    circle,
    rgba(255, 165, 0, 0.2) 30%,
    ${colors.lightGrey} 75%,
    ${colors.lightGrey} 100%
  );
`;

const HeroContentWrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: inherit;
  display: flex;
`;

const HeroContent = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: inherit;
`;

const HeroHeader = styled(StyledHeader)`
  margin: 40px auto;
  font-size: 8rem;
  font-weight: 800;
  text-align: center;
  padding: 0 30px;

  @media (max-width: 1200px) {
    font-size: 4rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const SubHeader = styled(StyledParagraph)`
  margin: 0 auto;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  padding: 0 30px;
  max-width: 900px;
  color: ${colors.darkGrey};

  @media (max-width: 1200px) {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const HeroButton = styled(StyledSubmitButton)`
  display: flex;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 500;
  margin: 0 auto;
  margin-top: 40px;
  padding: 12px 30px;
  max-width: fit-content;
`;

export const Hero = () => {
  return (
    <SectionWrapper>
      <HeroBackground>
        <HeroBackGroundImage
          src='/section-background.jpeg'
          bottom={"160px"}
          left={"100px"}
        >
          <HeroGradient />
        </HeroBackGroundImage>
        <HeroBackGroundImage
          src='/section-background.jpeg'
          top={"180px"}
          right={"100px"}
        >
          <HeroGradient />
        </HeroBackGroundImage>
        <HeroContentWrapper>
          <HeroContent>
            <HeroHeader>Promotions on Autopilot</HeroHeader>
            <SubHeader>Experience the Best Growth on Social Media</SubHeader>
            <HeroButton>Get Started</HeroButton>
          </HeroContent>
        </HeroContentWrapper>
      </HeroBackground>
    </SectionWrapper>
  );
};
