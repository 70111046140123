import { usePlatformContext } from "config/context";
import { ContentWrapper, LayoutWrapper } from "./layout.styles";
import { Outlet } from "react-router";
import { Header } from "./header";
import { Footer } from "./footer";
import { DrawerHeader } from "./header/drawer-header";

export default function Layout() {
  const context = usePlatformContext();
  return (
    <LayoutWrapper>
      <DrawerHeader />
      {!context.loading && <Header />}
      <ContentWrapper loading={context.loading}>
        {context.loading ? null : <Outlet />}
      </ContentWrapper>
      {!context.loading && <Footer />}
    </LayoutWrapper>
  );
}
