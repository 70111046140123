export const Constants = {
  TOKEN: "token",
  theme: {
    colors: {
      primary: "#1279FC", // Blue (buttons, etc.)
      secondary: "#FFA500", // Orange (buttons, etc.)
      white: "#FFFFFF", // White (backgrounds, etc.)
      lightGrey: "#F5F5F5", // Light Grey (inputs, etc.)
      mediumGrey: "#D3D3D3", // Medium Grey (borders, etc.)
      darkGrey: "#696969", // Dark Grey (paragraphs, info, formfield text etc.)
      charcoalGrey: "#000", // Charcoal Grey (important text, etc.)
    },
  },
  faq: [
    {
      question: "How can I join the early IG Accounts program?",
      answer:
        "All you need to do is create an account on our platform. Once you've done that, you're automatically enrolled as an early user. This gives you free access to our premium plan for a certain period, no extra steps required. Enjoy the many perks that come with being an early user!",
    },
    {
      question: "What are the benefits of the premium plan?",
      answer:
        "You not only get all features of the free plan but also enjoy a load of perks including lower commission rates, an early user badge, VIP community access, and a verified seller rank. Additionally, you get premium customer support, VIP access to special events, and an opportunity to be featured in our testimonials.",
    },
    {
      question: "When will I receive the client's payment in my balance?",
      answer:
        "As soon as a client purchases one of your promotion packages, their payment will be held securely on our side. Once the promotion period has ended and the order is marked as complete, the funds are then released to your account balance. This system ensures the safety and satisfaction of both parties involved.",
    },
    {
      question: "How can I withdraw my earnings?",
      answer:
        "You can withdraw your earnings from the 'Payout' section of the platform. You can choose the amount you wish to withdraw and the payment method.",
    },
    {
      question: "How does the calendar scheduling work?",
      answer:
        "You can set general schedules, block specific times, and manage how many promotions you wish to accept during a particular period. This helps you keep your promotional work organized and efficient.",
    },
    {
      question: "Can I customize my public page?",
      answer:
        "Sure, you can add a description, upload photos, specify promotion packages, and even show analytics and insights of previous promotions. Our goal is to make your page as appealing as possible.",
    },
  ],
  features: [
    {
      icon: "🔥",
      title: "Automated Orders",
      description:
        "Waste less time chatting by letting your clients fully configure their needs in our checkout",
    },
    {
      icon: "📅",
      title: "Effortless Scheduling",
      description:
        "Manage your promotions with a calendar, enabling you to effortlessly plan ahead",
    },
    {
      icon: "✏️",
      title: "Personalized Promotion Offers",
      description:
        "Create custom promotion packages that align with your brand and audience needs",
    },
    {
      icon: "🖥️",
      title: "Personal Web Page",
      description:
        "Craft your personal page in the web to reflect your brand, making it attractive to potential buyers",
    },
    {
      icon: "🔒",
      title: "Secure Transactions",
      description:
        "Rest easy knowing your transactions are safe and secure with our platform",
    },
    {
      icon: "📈",
      title: "Performance Analytics",
      description:
        "Track your promotions' performance with our integrated analytics",
    },
  ],
  pricing: [
    {
      title: "Free",
      price: "0",
      features: [
        "Personalized Public Profiles",
        "Effortless Scheduling",
        "Custom Promotion Offers",
        "Automated Orders",
        "Lower Commission Rates",
        "Higher Ranking in Discover",
      ],
      noInclude: ["Lower Commission Rates", "Higher Ranking in Discover"],
      buttonText: "Get Started",
    },
    {
      title: "Pro",
      early: true,
      price: "15",
      features: [
        "Personalized Public Profiles",
        "Effortless Scheduling",
        "Custom Promotion Offers",
        "Automated Orders",
        "Lower Commission Rates",
        "Higher Ranking in Discover",
      ],
      special: {
        text: "Free for Early Members",
        newPrice: "0",
      },
      noInclude: [],
      buttonText: "Get Started",
    },
  ],

  pricingTable: {
    features: [
      {
        section: "Basic",
        features: [
          {
            name: "Secure Transactions",
            pro: "check",
            free: "check",
          },
          {
            name: "Performance Analytics",
            pro: "check",
            free: "check",
          },
        ],
      },
      {
        section: "Promotion Automation",
        features: [
          {
            name: "Public Profile Creation & Display",
            pro: "check",
            free: "check",
          },
          {
            name: "Personalized Promotion Offers",
            pro: "check",
            free: "check",
          },
          {
            name: "Effortless Scheduling",
            pro: "check",
            free: "check",
          },
          {
            name: "Automated Orders",
            pro: "check",
            free: "check",
          },
          {
            name: "Enhanced Profile Customization",
            pro: "check",
          },
          {
            name: "Lower Commission Rates",
            pro: "check",
          },
          {
            name: "Higher Ranking in Discover Feature",
            pro: "check",
          },
        ],
      },
      {
        section: "Support Services",
        features: [
          {
            name: "General support tickets",
            pro: "check",
            free: "check",
          },
          {
            name: "VIP Community Access",
            pro: "check",
          },
          {
            name: "Priority support",
            pro: "check",
          },
        ],
      },
    ],
  },

  seo: {
    title: "Bestgrowth",
    description:
      "Bestgrowth is a platform that connects Instagram influencers with brands and businesses. We help influencers monetize their accounts and help brands grow their business.",
    about: {
      title: "Why Us",
      description:
        "Bestgrowth is a platform that connects Instagram influencers with brands and businesses. We help influencers monetize their accounts and help brands grow their business.",
    },
    faq: {
      title: "FAQ",
      description:
        "Bestgrowth is a platform that connects Instagram influencers with brands and businesses. We help influencers monetize their accounts and help brands grow their business.",
    },
    pricing: {
      title: "Pricing",
      description:
        "Bestgrowth is a platform that connects Instagram influencers with brands and businesses. We help influencers monetize their accounts and help brands grow their business.",
    },
    feature: {
      title: "Features",
      description:
        "Bestgrowth is a platform that connects Instagram influencers with brands and businesses. We help influencers monetize their accounts and help brands grow their business.",
    },
  },
};
