import { FaqSection } from "components/sections/faq";
import { GetStarted } from "components/sections/get-started";
import { PageWrapper } from "components/utils/page-wrapper";
import { Constants } from "config/constants";
import { usePlatformContext } from "config/context";

export default function Faq() {
  const seo = Constants.seo.faq;
  const context = usePlatformContext();
  return (
    <PageWrapper seo={seo}>
      <GetStarted
        category='Help Desk'
        header='Contact Us'
        text='Have a question? Check out our Faq Section below. Can’t find the answer here? Then you can contact us and we’ll get back to you as soon as possible.'
        button='Get In Touch'
        onClick={() => {
          context.redirect("mailto:team@hirelance.app");
        }}
      />
      <FaqSection />
    </PageWrapper>
  );
}
