import { FaqSection } from "components/sections/faq";
import { FeatureGrid } from "components/sections/feature-grid";
import { SectionBlock } from "components/sections/text-image";
import { PageWrapper } from "components/utils/page-wrapper";
import { Constants } from "config/constants";
import Step3 from "assets/sections/br1.png";

export default function Features() {
  const seo = Constants.seo.feature;
  return (
    <PageWrapper seo={seo}>
      <SectionBlock
        header={"Minimal Input for Maximum Output"}
        text={
          "Discover the Benefits of Bestgrowth from Automated Booking Systems, Customizable Public Pages and Secure Checkouts to Powerful Performance Tracking and more."
        }
        src={Step3}
      />
      <FeatureGrid />
      <FaqSection />
    </PageWrapper>
  );
}
