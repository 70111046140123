import { usePlatformContext } from "config/context";
import { useSearchParams } from "react-router-dom";

export default function Redirect() {
  const [searchParams] = useSearchParams();
  const context = usePlatformContext();
  const url = searchParams.get("url");
  if (url) {
    context.redirect(url);
  } else {
    context.redirect("/");
  }
  return null;
}
