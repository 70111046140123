import { Constants } from "config/constants";
import styled from "styled-components";

const colors = Constants.theme.colors;

export const LayoutWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background-color: ${colors.charcoalGrey};
`;

export const ContentWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  transition: margin-top;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${colors.lightGrey};
`;
