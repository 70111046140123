import { FaqSection } from "components/sections/faq";
import { GetStarted } from "components/sections/get-started";
import { SectionBlock } from "components/sections/text-image";
import { PageWrapper } from "components/utils/page-wrapper";
import { Constants } from "config/constants";
import Customers from "assets/sections/customers.png";
import Mission from "assets/sections/mission.png";

export default function About() {
  const seo = Constants.seo.about;
  return (
    <PageWrapper seo={seo}>
      <SectionBlock
        hero={true}
        header='A new era of Marketing'
        text='You all know it ... there are tons of ads out there and almost everyone starts to block them. So businesses are seeking for alternatives. Our vision is it to make our platform to the new place for Social Media Marketing Agencies, E-Commerce Brands and lots of other companies.'
        section={"Our Vision"}
        src={Customers}
      />
      <GetStarted
        category={"Our Story"}
        header='Built by IG Brands for IG Brands'
        text='We built Bestgrowth to help big social media brands maximize their monetization potential. Our goal was to provide you with powerful leads by giving both parties a professional platform where they can easily use our service.'
        button='Get Started'
      />
      <SectionBlock
        header='Our Mission'
        text='We want to help you grow your promotion business by providing you with the best tools and generating leads 24 / 7. We want to make the whole process of promotion booking and lead generation as easy as possible.'
        section={"Our Mission"}
        right={true}
        src={Mission}
      />
      <FaqSection />
    </PageWrapper>
  );
}
