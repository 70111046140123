import { DropDownCard } from "components/utils/dropdown";
import { HeadingBlock } from "components/utils/header-block";
import { SectionWrapper } from "components/utils/section-wrapper";
import { Constants } from "config/constants";
import { useState } from "react";
import styled from "styled-components";

// const colors = Constants.theme.colors;

const BlockWrapper = styled.div`
  flex-direction: column;
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin: 10px auto;
  border-radius: 15px;
  align-items: center;

  .header-block {
    max-width: 700px;
  }
`;

const GridContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const FaqSection = () => {
  const [selected, setSelected] = useState(-1);

  const select = (index) => {
    if (selected === index) {
      setSelected(-1);
    } else {
      setSelected(index);
    }
  };

  return (
    <SectionWrapper>
      <BlockWrapper>
        <HeadingBlock
          category={"Faq Section"}
          header={"Any Questions Left?"}
          align={"center"}
          sub={
            "If you cannot answer your questions in here, feel free to contact us at any time."
          }
        />
        <GridContainer>
          {Constants.faq.map((item, index) => {
            return (
              <DropDownCard
                key={index}
                index={index}
                open={selected === index}
                select={select}
                question={item.question}
                answer={item.answer}
              />
            );
          })}
        </GridContainer>
      </BlockWrapper>
    </SectionWrapper>
  );
};
