import { Constants } from "config/constants";
import styled from "styled-components";
import { SectionWrapper } from "components/utils/section-wrapper";
import { HeadingBlock } from "components/utils/header-block";
import { StyledHeader, StyledParagraph } from "components/utils/text";

const colors = Constants.theme.colors;

const BlockWrapper = styled.div`
  flex-direction: column;
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin: 10px auto;
  border-radius: 15px;
  align-items: center;

  .header-block {
    max-width: 700px;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  width: 100%;
  margin: 20px auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const GridItem = styled.div`
  padding: 12px;
  display: flex;
  min-height: 220px;
  background-color: ${colors.white};
  border-radius: 10px;
  box-shadow: 0px 0px 45px -10px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  justify-content: center;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;

  h1 {
    font-size: 1.3rem;
    font-weight: 700;
  }

  .icon {
    margin-right: 10px;
    font-size: 1.8rem;
  }
`;

export const FeatureGrid = () => {
  return (
    <SectionWrapper>
      <BlockWrapper>
        <HeadingBlock
          category={"Features"}
          header={"Our Features"}
          align={"center"}
          sub={
            "These are the tools and solutions which maximize your orders and save you hours at the same time."
          }
        />
        <GridContainer>
          {Constants.features.map((feature, index) => {
            return (
              <GridItem key={index}>
                <CardHeader>
                  <StyledParagraph className='icon'>
                    {feature.icon}
                  </StyledParagraph>
                  <StyledHeader>{feature.title}</StyledHeader>
                </CardHeader>
                <StyledParagraph>{feature.description}</StyledParagraph>
              </GridItem>
            );
          })}
        </GridContainer>
      </BlockWrapper>
    </SectionWrapper>
  );
};
