import { StyledSubmitButton } from "components/utils/buttons";
import { HeadingBlock } from "components/utils/header-block";
import { SectionWrapper } from "components/utils/section-wrapper";
// import { Constants } from "config/constants";
import styled from "styled-components";

// const colors = Constants.theme.colors;

const BlockWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.right ? "row-reverse" : "row")};
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 15px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1000px) {
    align-items: initial;
    flex-direction: column;
  }
`;

const TextSection = styled.div`
  display: flex;
  max-width: 600px;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin: ${(props) => (props.right ? "0 0 0 1rem" : "0 1rem 0 0")};

  @media (max-width: 1000px) {
    margin: 0;
    margin-bottom: 2rem;
  }

  .submit-button {
    margin-top: 15px;
    width: fit-content;
    padding: 12px 30px;
  }
`;

const ContentSection = styled.div`
  display: flex;
  max-width: ${(props) => (!props.hero ? "500px" : "100%")};
  flex-direction: column;
  flex: 1;

  @media (max-width: 1200px) {
    max-width: 500px;
  }

  img {
    object-fit: cover;
    border-radius: 15px;
  }
`;

export const SectionBlock = ({ ...props }) => {
  const { right, section, header, text, src, hero } = props;
  return (
    <SectionWrapper hero={hero}>
      <BlockWrapper right={right && right}>
        <TextSection right={right}>
          <HeadingBlock category={section} header={header} text={text} />
          <StyledSubmitButton className='submit-button'>
            Get Started
          </StyledSubmitButton>
        </TextSection>
        <ContentSection right={right} hero={hero}>
          <img
            src={src || "https://source.unsplash.com/random/600x600"}
            alt={"content"}
          />
        </ContentSection>
      </BlockWrapper>
    </SectionWrapper>
  );
};
