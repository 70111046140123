import Layout from "components/layout";
import NotFound from "pages/404";
import About from "pages/about";
import Faq from "pages/faq";
import Features from "pages/features";
import Home from "pages/index";
import Pricing from "pages/pricing";
import Redirect from "pages/redirect";

export const routes = [
  {
    path: "*",
    element: NotFound,
  },
  {
    path: "/redirect",
    element: Redirect,
  },
  {
    element: Layout,
    children: [
      {
        path: "/",
        element: Home,
      },
      {
        path: "/features",
        element: Features,
      },
      {
        path: "/pricing",
        element: Pricing,
      },
      {
        path: "/why-us",
        element: About,
      },
      {
        path: "faq",
        element: Faq,
      },
    ],
  },
];
