import { Constants } from "config/constants";
import styled from "styled-components";
import { StyledHeader, StyledParagraph } from "./text";
import { StyledIconButton } from "./buttons";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const colors = Constants.theme.colors;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${colors.lightGrey};
  border-radius: 10px;
  margin: 15px 0px;
  cursor: pointer;
  background-color: ${colors.white};

  h1 {
    font-size: 1.5rem;
    color: ${colors.charcoalGrey};
  }

  p {
    font-size: 1.3rem;
  }
`;

const CardQuestion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    color: ${colors.darkGrey};
  }
`;

const CardContent = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const DropDownCard = (props) => {
  let open = props.open;
  return (
    <CardWrapper onClick={() => !open && props.select(props.index)}>
      <CardQuestion>
        <StyledHeader>{props.question}</StyledHeader>
        <StyledIconButton onClick={() => props.select(props.index)}>
          {open ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </StyledIconButton>
      </CardQuestion>
      {open && (
        <CardContent>
          <StyledParagraph>{props.answer}</StyledParagraph>
        </CardContent>
      )}
    </CardWrapper>
  );
};
