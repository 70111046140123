import { usePlatformContext } from "config/context";
import "react-toastify/dist/ReactToastify.css";
import { AppRoutes } from "routes";
import { styled } from "styled-components";
import { Constants } from "config/constants";
import { Loading } from "components/utils/loading";

const colors = Constants.theme.colors;

const LoadingContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: ${(props) => (props.loading ? 9999 : -999)};
  opacity: ${(props) => (props.loading ? 1 : 0)};
  background-color: ${colors.charcoalGrey};
  transition: opacity 0.3s linear, z-index 0.3s linear,
    background-color 0.3s linear;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function App() {
  const context = usePlatformContext();
  return (
    <>
      <LoadingContainer loading={context.loading}>
        <Loading />
      </LoadingContainer>
      <AppRoutes loading={context.loading} />
    </>
  );
}

export default App;
