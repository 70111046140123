import styled from "styled-components";
import { Constants } from "config/constants";
import { SectionWrapper } from "components/utils/section-wrapper";
import { HeadingBlock } from "components/utils/header-block";
import { StyledHeader, StyledParagraph } from "components/utils/text";
import { StyledSubmitButton } from "components/utils/buttons";
import { AiFillCheckCircle } from "react-icons/ai";
import { VscError } from "react-icons/vsc";

const colors = Constants.theme.colors;

const BlockWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 10px auto;
  border-radius: 15px;
  flex-direction: column;

  @media (max-width: 1000px) {
    padding: 20px;
    flex-direction: column;
  }

  .pricing-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }
`;

const PackageContainer = styled.div`
  position: relative;
  border-radius: 15px;
  margin: 0 20px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
  background-color: ${colors.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .nodisplay {
    display: none;
  }

  .badge {
    background-color: ${colors.secondary};
    width: fit-content;
    color: ${colors.white};
    padding: 0.3rem 0.8rem;
    border-radius: 100px;
    position: absolute;
    top: -10px;
    right: -5px;
    font-size: 1rem;
    font-weight: 800;

    @media (max-width: 768px) {
      font-size: 1rem;
      left: 0;
      right: 0;
      top: -15px;
      margin: 0 auto;
    }
  }

  .cutted {
    .old {
      text-decoration: line-through;
      color: ${colors.mediumGrey};
      font-size: 1.5rem;
    }
  }

  svg {
    font-size: 1.2rem;
    min-width: 1.2rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  .price {
    margin: 20px 0;
    h1 {
      color: ${colors.darkGrey};
      font-size: 2rem;
    }
    .offer {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      .new {
        color: ${colors.darkGrey};
        margin-right: 10px;
      }
    }
    p {
      font-size: 1.2rem;
      color: ${colors.darkGrey};
    }
  }

  button {
    padding: 15px;
  }

  .feature-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    padding: 0 20px;

    .failure {
      svg {
        color: red !important;
      }
    }

    .feature-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 10px 0;

      svg {
        color: ${colors.primary};
        font-size: 1.2rem;
        margin-right: 10px;

        @media (max-width: 768px) {
          font-size: 1.5rem;
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin: 20px 0;
  }
`;

const FeatureItem = (props) => {
  console.log(props.exclude);
  let include = props.exclude.find((item) => props.text === item);
  return (
    <div className={"feature-item" + (include !== undefined ? " failure" : "")}>
      {include !== undefined ? <VscError /> : <AiFillCheckCircle />}
      <StyledParagraph>{props.text}</StyledParagraph>
    </div>
  );
};

const Package = (props) => {
  return (
    <PackageContainer>
      <StyledParagraph className={props.special ? "badge" : "nodisplay"}>
        {props.special && props.special.text}
      </StyledParagraph>
      <StyledHeader>{props.title}</StyledHeader>
      <div className={"price" + (props.special !== undefined ? " cutted" : "")}>
        <div className='offer'>
          <StyledHeader className={props.special ? "new" : "nodisplay"}>
            ${props.special && props.special.newPrice}
          </StyledHeader>
          <StyledHeader className='old'>${props.price}</StyledHeader>
        </div>
        <StyledParagraph>per month</StyledParagraph>
      </div>
      <StyledSubmitButton>{props.buttonText}</StyledSubmitButton>
      <div className='feature-list'>
        {props.features.map((feature, index) => {
          return (
            <FeatureItem key={index} text={feature} exclude={props.exclude} />
          );
        })}
      </div>
    </PackageContainer>
  );
};

export const PricingPackage = () => {
  return (
    <SectionWrapper>
      <HeadingBlock
        category='Pricing'
        header='Simple pricing for everyone'
        sub='Pricing Solutions for Scaling your Promotion Business'
        align='center'
      />
      <BlockWrapper>
        <div className='pricing-container'>
          {Constants.pricing.map((packageItem, index) => {
            return (
              <Package
                key={index}
                title={packageItem.title}
                price={packageItem.price}
                buttonText={packageItem.buttonText}
                features={packageItem.features}
                exclude={packageItem.noInclude}
                special={packageItem.special}
              />
            );
          })}
        </div>
      </BlockWrapper>
    </SectionWrapper>
  );
};
