import styled from "styled-components";
import { StyledIconButton, StyledSubmitButton } from "components/utils/buttons";
import { AiOutlineClose } from "react-icons/ai";
import { StyledLink } from "components/utils/text";
import { usePlatformContext } from "config/context";
import { Logo } from "components/utils/logo";
import { Constants } from "config/constants";

const colors = Constants.theme.colors;

const DrawerContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: ${(props) => (props.open ? 9000 : -9999)};
  transition: all 1s ease-in-out;

  .open {
    transform: translateX(0);
  }
`;

const Drawer = styled.header`
  max-width: 500px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.lightGrey};
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  padding: 20px;

  .logo {
    margin: 20px 0;
  }

  .logo-wrapper {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-direction: row;

    .close {
      svg {
        color: ${colors.charcoalGrey};
      }
    }
  }

  .submit {
    width: 100%;
  }
`;

const DrawerNav = styled.nav`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 20px;
`;

const DrawerList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const DrawerItem = styled.li`
  padding: 10px;

  a {
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;
    color: ${colors.charcoalGrey};

    &:hover {
      font-weight: 500;
    }
  }

  .active {
    color: ${colors.primary};
  }
`;

export const DrawerHeader = () => {
  const pages = ["/", "/features", "/pricing", "/why-us"];
  const names = ["Home", "Features", "Pricing", "Why Us"];
  const context = usePlatformContext();
  return (
    <DrawerContainer open={context.menu}>
      <Drawer className={context.menu && "open"}>
        <DrawerNav>
          <div className='logo-wrapper'>
            <Logo />
            <StyledIconButton className='close' onClick={context.toggleMenu}>
              <AiOutlineClose />
            </StyledIconButton>
          </div>
          <DrawerList>
            {pages.map((page, index) => {
              return (
                <DrawerItem key={index}>
                  <StyledLink
                    to={page}
                    className={context.current === page && "active"}
                  >
                    {names[index]}
                  </StyledLink>
                </DrawerItem>
              );
            })}
          </DrawerList>
        </DrawerNav>
        <StyledSubmitButton className='submit'>Log In</StyledSubmitButton>
        <StyledSubmitButton className='submit'>Get Started</StyledSubmitButton>
      </Drawer>
    </DrawerContainer>
  );
};
