import { FaqSection } from "components/sections/faq";
import { PricingPackage } from "components/sections/pricing-package";
import { PricingTable } from "components/sections/pricing-table";
import { PageWrapper } from "components/utils/page-wrapper";
import { Constants } from "config/constants";

export default function Pricing() {
  const seo = Constants.seo.pricing;
  return (
    <PageWrapper seo={seo}>
      <PricingPackage />
      <PricingTable />
      <FaqSection />
    </PageWrapper>
  );
}
