import LogoImg from "assets/images/logo.svg";
import styled from "styled-components";
import { CustomLink } from "components/utils/buttons";

export const LogoContainer = styled(CustomLink)`
  text-decoration: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  img {
    width: 12rem;
  }

  h1 {
    font-size: 1%.8;
    font-weight: 700;
    color: black;
  }
`;

export const Logo = () => {
  return (
    <LogoContainer to={"/"}>
      <img src={LogoImg} alt='logo' />
    </LogoContainer>
  );
};
