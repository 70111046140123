import { GetStarted } from "components/sections/get-started";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  margin-top: ${(props) => (props.home ? 0 : "150px")};
`;

export const PageWrapper = ({ children, ...props }) => {
  return (
    <Wrapper home={props.home}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{props.seo && props.seo.title}</title>
        <meta name='description' content={props.seo && props.seo.description} />
      </Helmet>
      {children}
      {props.onlyFooter || (
        <GetStarted
          category={"Get Started"}
          header={"Join us Today"}
          text={
            "Start your journey with us today and get Early Access Benefits. You can find more information about our Early Access Program in our FAQ or Pricing Section."
          }
          button={"Get Started"}
        />
      )}
    </Wrapper>
  );
};
