import { FaqSection } from "components/sections/faq";
import { FeatureGrid } from "components/sections/feature-grid";
import { GetStarted } from "components/sections/get-started";
import { Hero } from "components/sections/hero";
import { PricingPackage } from "components/sections/pricing-package";
import { SectionBlock } from "components/sections/text-image";
import { PageWrapper } from "components/utils/page-wrapper";
import { Constants } from "config/constants";
import Step1 from "assets/sections/personal-link.jpg";
import Step3 from "assets/sections/br1.png";

export default function Home() {
  const seo = Constants.seo;
  return (
    <PageWrapper home={true} seo={seo}>
      <Hero />
      <SectionBlock
        section={"Step 1"}
        header={"Your personal Link"}
        text={
          "After you verified your social media account, you are able to create your personal link where clients can book promotions. We provide you with various themes you can choose from to customize your personal page."
        }
        src={Step1}
      />
      <GetStarted
        category={"Step 2"}
        header={"Setup your Promotion Offers"}
        text={
          "On our platform you can enable your clients to choose from a variety of promotion offers which you can predefine. This helps you to save time and makes communication way easier. No worries, a chat is also available for further questions. 😁"
        }
        button={"Get Started"}
      />
      <SectionBlock
        right={true}
        section={"Step 3"}
        header={"Orders on demand"}
        text={
          "Because of our vision to provide you with as many quality leads as possible we are also giving you the option to decline offers and schedule your availability to filter your clients even more. This helps you organize your time and allows you to focus on your target audience."
        }
        src={Step3}
      />
      <GetStarted
        category={"Our Story"}
        header='Built by IG Brands for IG Brands'
        text='We built Bestgrowth to help big social media brands maximize their monetization potential. Our goal was to provide you with powerful leads by giving both parties a professional platform where they can easily use our service.'
        button='Get Started'
      />
      <FeatureGrid />
      <PricingPackage />
      <FaqSection />
    </PageWrapper>
  );
}
