import { Logo } from "components/utils/logo";
import {
  CopyRight,
  FooterColumn,
  FooterContainer,
  FooterHeader,
  FooterLink,
  FooterLinksRow,
  FooterRow,
} from "./footer.styles";

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterRow>
        <FooterColumn expand={true}>
          <Logo />
          <CopyRight>Copyright©Bestgrowth {new Date().getFullYear()}</CopyRight>
        </FooterColumn>
        <FooterLinksRow>
          <FooterColumn>
            <FooterHeader>Quick Links</FooterHeader>
            <FooterLink to={"/"}>Home</FooterLink>
            <FooterLink to={"/features"}>Features</FooterLink>
            <FooterLink to={"/pricing"}>Pricing</FooterLink>
            <FooterLink to={"/why-us"}>Why Us</FooterLink>
          </FooterColumn>
          <FooterColumn>
            <FooterHeader>Support</FooterHeader>
            <FooterLink to={"/faq"}>Help Desk</FooterLink>
            <FooterLink
              to={`/redirect?url=${process.env.REACT_APP_Notion_Link}}`}
            >
              Roadmap
            </FooterLink>
            <FooterLink to={`/redirect?url=${process.env.REACT_APP_DC_Link}`}>
              Discord Server
            </FooterLink>
          </FooterColumn>
          <FooterColumn>
            <FooterHeader>Company</FooterHeader>
            <FooterLink to={"/terms"}>Terms</FooterLink>
            <FooterLink to={"/privacy"}>Privacy</FooterLink>
          </FooterColumn>
        </FooterLinksRow>
      </FooterRow>
    </FooterContainer>
  );
};
