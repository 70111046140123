import styled from "styled-components";
import { Constants } from "config/constants";
import { SectionWrapper } from "components/utils/section-wrapper";
import { HeadingBlock } from "components/utils/header-block";
import { StyledHeader } from "components/utils/text";
import { AiFillCheckCircle } from "react-icons/ai";
import { BsFillGearFill } from "react-icons/bs";
import { VscError } from "react-icons/vsc";

const colors = Constants.theme.colors;

const BlockWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 10px auto;
  background-color: ${colors.white};
  border-radius: 15px;
  padding: 4rem 3rem;
  box-shadow: 0px 0px 45px -10px rgba(0, 0, 0, 0.1);
  flex-direction: column;

  @media (max-width: 1000px) {
    padding: 20px;
    flex-direction: column;
  }
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  color: ${colors.charcoalGrey};

  tbody {
    width: 100%;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  svg {
    font-size: 1.2rem;
    color: ${colors.primary};
  }

  tr {
    font-size: 0.85rem;
    border-bottom: 1px solid ${colors.mediumGrey};

    @media (max-width: 768px) {
      display: flex;
      width: 100%;
      flex-direction: row;
    }

    .failure svg {
      color: red;
    }
  }

  td {
    padding: 1.5rem 0;
    border-left: 1px solid ${colors.mediumGrey};
  }

  td:first-child {
    text-align: left;
    justify-content: flex-start;
    border-left: none;

    @media (max-width: 768px) {
      display: none;
    }
  }

  td:nth-child(2) {
    @media (max-width: 768px) {
      border-left: none;
    }
  }

  .classic-tr {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .responsive-tr {
    display: none;
    td {
      display: flex !important;
      justify-content: center;
      flex: 1;

      &:nth-child(2) {
        border-left: 1px solid ${colors.mediumGrey};
      }
    }

    border: none;

    .item-name {
      font-size: 1rem;
      font-weight: 400;
      color: ${colors.charcoalGrey};
      text-align: left;
      padding: 0.8rem 0;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: row;
    }
  }

  .section-th {
    td {
      flex-direction: row;
      border: none;
    }

    h1 {
      font-size: 1.3rem;
      font-weight: 800;
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: 10px;
      color: ${colors.primary};
    }

    @media (max-width: 768px) {
      .header {
        flex: 1;
        justify-content: center;
        display: flex;
      }
    }
  }

  .border-bottom {
    border-bottom: 1px solid ${colors.mediumGrey} !important;
  }

  .top {
    td {
      @media (max-width: 768px) {
        display: flex !important;
        justify-content: center;
        flex: 1;
        &:first-child {
          display: none !important;
        }
      }
    }

    border: none;

    h1 {
      justify-content: center;
    }
  }
`;

const TableHeader = () => {
  return (
    <tr className='top section-th'>
      <td></td>
      <td>
        <StyledHeader>Free</StyledHeader>
      </td>
      <td>
        <StyledHeader>Pro</StyledHeader>
      </td>
    </tr>
  );
};

const TableSection = ({ header, icon }) => {
  return (
    <tr className='section-th'>
      <td className='header'>
        <StyledHeader>
          {icon}
          {header}
        </StyledHeader>
      </td>
      <td></td>
      <td></td>
    </tr>
  );
};

const TableSectionItem = ({ name, free, pro }) => {
  return (
    <>
      <tr className='classic-tr'>
        <td>{name}</td>
        <td className={free === undefined && "failure"}>
          {free === "check" ? (
            <AiFillCheckCircle />
          ) : free === undefined ? (
            <VscError />
          ) : (
            free
          )}
        </td>
        <td className={pro === undefined && "failure"}>
          {pro === "check" ? (
            <AiFillCheckCircle />
          ) : pro === undefined ? (
            <VscError />
          ) : (
            pro
          )}
        </td>
      </tr>
      <>
        <tr className='responsive-tr tr-header'>
          <td className='item-name'>{name}</td>
        </tr>
        <tr className='responsive-tr border-bottom'>
          <td className={"item-value" + (free === undefined && " failure")}>
            {free === "check" ? (
              <AiFillCheckCircle />
            ) : free === undefined ? (
              <VscError />
            ) : (
              free
            )}
          </td>
          <td className={"item-value" + (pro === undefined && " failure")}>
            {pro === "check" ? (
              <AiFillCheckCircle />
            ) : pro === undefined ? (
              <VscError />
            ) : (
              free
            )}
          </td>
        </tr>
      </>
    </>
  );
};

export const PricingTable = () => {
  return (
    <SectionWrapper>
      <BlockWrapper>
        <HeadingBlock
          header='Flexible and transparent pricing'
          sub='Pricing built for businesses of all sizes. Always know what you’ll pay.'
          align='center'
        />
        <Table>
          <tbody>
            <TableHeader />
            {Constants.pricingTable.features.map((section, index) => (
              <>
                <TableSection
                  header={section.section}
                  icon={<BsFillGearFill />}
                />
                {section.features.map((item, index) => (
                  <TableSectionItem
                    name={item.name}
                    free={item.free}
                    pro={item.pro}
                  />
                ))}
              </>
            ))}
          </tbody>
        </Table>
      </BlockWrapper>
    </SectionWrapper>
  );
};
