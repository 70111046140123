import { StyledSubmitButton } from "components/utils/buttons";
import { HeadingBlock } from "components/utils/header-block";
import { SectionWrapper } from "components/utils/section-wrapper";
import { Constants } from "config/constants";
import styled from "styled-components";

const colors = Constants.theme.colors;

const BlockWrapper = styled.div`
  max-width: 1200px;
  margin: 10px auto;
  width: 100%;
  display: flex;
  border-radius: 15px;
  flex-direction: column;
  padding: 100px;
  background-color: ${colors.charcoalGrey};

  @media (max-width: 768px) {
    padding: 20px;
  }

  .header-block {
    max-width: 700px;
    margin: 0 auto;
  }
`;

const StartButton = styled(StyledSubmitButton)`
  width: fit-content;
  padding: 12px 30px;
  margin: 0 auto;
  margin-top: 30px;
`;

export const GetStarted = (props) => {
  return (
    <SectionWrapper>
      <BlockWrapper>
        <HeadingBlock
          category={props.category}
          light={true}
          header={props.header}
          align='center'
          text={props.text}
        />
        <StartButton onClick={props.onClick && props.onClick}>
          {props.button}
        </StartButton>
      </BlockWrapper>
    </SectionWrapper>
  );
};
